import React, { Component } from "react";
import config from "../config";
import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    console.log("In the constructor of the Login");
    this.state = {
      username: "",
      password: "",
      alertMessage: '',
      showModal: false
    };
  }

  componentDidMount() {
    const redirectURL = config.AUTH_DIAG_ENVIRONMENT === 'DEV' ? 'http://localhost:4000/Home' : "https://" + window.location.hostname + "/Home";
    window.location.assign(config.cognito.DOMAIN + "/authorize?client_id=" + config.cognito.APP_CLIENT_ID + "&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + redirectURL);
  }

  render() {
    return (
      <div>
        <div className="Login col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 white-container">
        </div>
      </div>
    );
  }
}
